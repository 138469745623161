import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import * as classNames from "@css/component/FeaturedImage.module.scss";

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData
        };
    };
};

/**
 * @type FeaturedImageProps
 */
export type FeaturedImageProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    gatsbyImageData?: IGatsbyImageData;
    variant?: string;
    loading?: "eager" | "lazy";
};

/**
 * @const FeaturedImage
 */
const FeaturedImage = (props: FeaturedImageProps) => {
    const {
        children,
        className,
        gatsbyImageData,
        variant,
        loading,
        ...restProps
    } = props;

    const data: FileData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "placeholder.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 1920, quality: 80)
                }
            }
        }
    `);

    return (
        <div { ...restProps } className={ clsx(classNames.featuredImage, {
            [classNames.home]: variant == "home", [classNames.card]: variant == "card",
            [classNames.employee]: variant == "employee", [classNames.employeeSingle]: variant == "employeeSingle"
        }, className) }>
            <GatsbyImage
                alt="Placeholder"
                loading={ loading || "lazy" }
                className={ classNames.image }
                image={ gatsbyImageData || data.file.childImageSharp.gatsbyImageData }
            />
            { children }
        </div>
    );
};

export default FeaturedImage;
